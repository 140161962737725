import { useSelector } from "react-redux";
import { selectAllData } from "./selectors";
import { FileData } from "./utils";
import { useMemo, useState } from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  styled,
  tableCellClasses,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";

type FileDataSearch = FileData & { type: string[] };

export function Dictionary(): JSX.Element | null {
  const allData = useSelector(selectAllData());
  const data: FileDataSearch = useMemo(() => {
    const temp: FileDataSearch = {
      dialect: [],
      italian: [],
      type: [],
    };
    for (const dataSlice of Object.entries(allData)) {
      for (const dialectWord of dataSlice[1].dialect) {
        temp.dialect.push(dialectWord);
        temp.type.push(dataSlice[0]);
      }
      for (const italianWord of dataSlice[1].italian) {
        temp.italian.push(italianWord);
      }
    }
    return temp;
  }, [allData]);

  const [searchInput, setSearchInput] = useState<string>("");

  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Stack my={6} mx={10}>
      <TextField
        id="outlined-basic"
        label="Trova parola"
        variant="outlined"
        value={searchInput}
        onChange={(newVal) => setSearchInput(newVal.target.value)}
        sx={{ mb: 4 }}
      />
      <TableContainer component={Paper}>
        <Table
          sx={isMatch ? { flexGrow: 1 } : { minWidth: 650 }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>Dialetto</StyledTableCell>
              <StyledTableCell align="left">Italiano</StyledTableCell>
              <StyledTableCell align="left">Categoria</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.dialect.map((dialect, index) => {
              if (
                searchInput.length === 0 ||
                dialect.toLowerCase().includes(searchInput.toLowerCase()) ||
                data.italian[index]
                  .toLowerCase()
                  .includes(searchInput.toLowerCase())
              ) {
                return (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {dialect}
                    </TableCell>
                    <TableCell align="left">{data.italian[index]}</TableCell>
                    <TableCell align="left">{data.type[index]}</TableCell>
                  </TableRow>
                );
              }
              return null;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1976d2",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
