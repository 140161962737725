export enum Pages {
  home = "home",
  animali = "animali",
  lavori = "lavori",
  dizionario = "dizionario",
}

export type PagesWithoutSpecificFile = "home" | "dizionario";

export type PagesWithFile = Exclude<
  keyof typeof Pages,
  PagesWithoutSpecificFile
>;

export function isPageWithFile(page: string): page is PagesWithFile {
  const types: { [key in PagesWithFile]: string } = {
    animali: "",
    lavori: "",
  };

  return Object.keys(types).includes(page);
}

export function isPageWithoutFile(
  page: string
): page is PagesWithoutSpecificFile {
  const excludedTypes: { [key in PagesWithoutSpecificFile]: string } = {
    home: "",
    dizionario: "",
  };

  return Object.keys(excludedTypes).includes(page);
}
