import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import HomeIcon from "@mui/icons-material/Home";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography/Typography";
import { Box, IconButton } from "@mui/material";
import { Pages } from "./pages-types";

type TopbarProps = {
  setCurrentPage(page: Pages): void;
};

export function Topbar({ setCurrentPage }: TopbarProps) {
  return (
    <AppBar position="static">
      <Toolbar>
        <Button
          variant="contained"
          startIcon={<HomeIcon />}
          onClick={() => setCurrentPage(Pages.home)}
        >
          HOME
        </Button>
        <Typography fontWeight={600} ml={7}>
          Impara il Dialetto
        </Typography>
        <Box component="div" flexGrow={1} />
        <IconButton
          size="large"
          sx={{ color: "white" }}
          onClick={() => setCurrentPage(Pages.dizionario)}
        >
          <MenuBookIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}
