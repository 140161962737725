import { configureStore } from "@reduxjs/toolkit";
import { vocabularySlice } from "./vocabularySlice";

export const store = configureStore({
  reducer: {
    vocabulary: vocabularySlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
