import { AnyAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Pages, isPageWithoutFile, isPageWithFile } from "./pages-types";
import { setFileData } from "./vocabularySlice";

export type FileData = {
  dialect: string[];
  italian: string[];
};

export async function readDataFile(filePath: string): Promise<FileData> {
  const content = (await fetch(filePath).then((r) => r.text())).split("\n");

  const dialect: string[] = [];
  const italian: string[] = [];

  for await (const line of content) {
    const l = `${line}`;
    const split = l.split(";");
    if (split.length === 2) {
      dialect.push(split[0]);
      italian.push(split[1]);
    }
  }

  return {
    dialect,
    italian,
  };
}

function shuffleArray(arr: string[], seed: number): string[] {
  let list = [...arr];
  var m = list.length,
    t,
    i;

  // While there remain elements to shuffle…
  while (m) {
    // Pick a remaining element…
    i = Math.floor(random(seed) * m--);

    // And swap it with the current element.
    t = list[m];
    list[m] = list[i];
    list[i] = t;
    ++seed;
  }

  return list;
}

function random(seed: number): number {
  var x = Math.sin(seed++) * 10000;
  return x - Math.floor(x);
}

export function shuffleFileData(data: FileData): FileData {
  const randSeed = Math.random();
  return {
    dialect: shuffleArray(data.dialect, randSeed),
    italian: shuffleArray(data.italian, randSeed),
  };
}

export async function fetchAllFileData(
  setHasFinished: (hasFinished: boolean) => void,
  dispatch: Dispatch<AnyAction>
): Promise<void> {
  const filePaths = Object.keys(Pages).map((page) => {
    return !isPageWithoutFile(page)
      ? { path: `/data/${page}.txt`, page }
      : undefined;
  });

  for (const filePath of filePaths) {
    if (!filePath) continue;
    if (!isPageWithFile(filePath.page)) continue;

    const data = await readDataFile(filePath.path);
    dispatch(setFileData(Object.assign(data, { type: filePath.page })));
  }

  setHasFinished(true);
}
