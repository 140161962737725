import { Pages, isPageWithFile } from "./pages-types";
import { RootState } from "./store";
import { FileData } from "./utils";
import { Vocabulary } from "./vocabularySlice";

export function selectAnimaliData(): (state: RootState) => FileData {
  return (state: RootState) => {
    return state.vocabulary.animali;
  };
}

export function selectLavoriData(): (state: RootState) => FileData {
  return (state: RootState) => {
    return state.vocabulary.lavori;
  };
}

export function selectPageData(
  page: Pages
): (state: RootState) => FileData | undefined {
  return (state: RootState) => {
    return isPageWithFile(page) ? state.vocabulary[page] : undefined;
  };
}

export function selectAllData(): (state: RootState) => Vocabulary {
  return (state: RootState) => {
    return {
      animali: selectAnimaliData()(state),
      lavori: selectLavoriData()(state),
    };
  };
}
