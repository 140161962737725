import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FileData } from "./utils";
import { PagesWithFile } from "./pages-types";

export type Vocabulary = {
  [key in PagesWithFile]: FileData;
};

const initial: Vocabulary = {
  animali: { dialect: [], italian: [] },
  lavori: { dialect: [], italian: [] },
};

export const vocabularySlice = createSlice({
  name: "vocabulary",
  initialState: initial,
  reducers: {
    setFileData: (
      state,
      action: PayloadAction<FileData & { type: PagesWithFile }>
    ) => {
      state[action.payload.type] = {
        dialect: action.payload.dialect,
        italian: action.payload.italian,
      };
    },
    setVocabulary: (state, action: PayloadAction<Vocabulary>) => {
      state = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setFileData, setVocabulary } = vocabularySlice.actions;
