import { Box } from "@mui/material";
import { useLayoutEffect, useState } from "react";
import { Page } from "./page";
import { Topbar } from "./topbar";
import { fetchAllFileData } from "./utils";
import { useDispatch } from "react-redux";
import { Pages } from "./pages-types";

export function App(): JSX.Element {
  const [currentPage, setCurrentPage] = useState<Pages>(Pages.home);
  const [finishedFetching, setFinishedFetching] = useState(false);

  const dispatch = useDispatch();

  useLayoutEffect(() => {
    async function fetching() {
      await fetchAllFileData(setFinishedFetching, dispatch);
    }

    fetching();
  }, [dispatch]);

  return (
    <>
      <Box component="div">
        <Topbar setCurrentPage={setCurrentPage} />
        {finishedFetching ? (
          <Page currentPage={currentPage} setCurrentPage={setCurrentPage} />
        ) : (
          <>Still downloading</>
        )}
      </Box>
    </>
  );
}
