import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { selectPageData } from "./selectors";
import { useSelector } from "react-redux";
import { Pages, PagesWithFile, isPageWithFile } from "./pages-types";
import { FileData, shuffleFileData } from "./utils";
import { Dictionary } from "./dictionary";

type PageProps = {
  currentPage: Pages;
  setCurrentPage(page: Pages): void;
};

export function Page({ currentPage, setCurrentPage }: PageProps): JSX.Element {
  switch (currentPage) {
    case Pages.home:
      return <Home setCurrentPage={setCurrentPage} />;
    case Pages.dizionario:
      return <Dictionary />;
    case Pages.animali:
    case Pages.lavori:
      return <Topic currentPage={currentPage} />;
  }
}

type HomeProps = {
  setCurrentPage(page: Pages): void;
};

function Home({ setCurrentPage }: HomeProps): JSX.Element {
  return (
    <Box
      component="div"
      display="flex"
      flexDirection="column"
      alignItems="center"
      mt={10}
    >
      <Typography
        textTransform="uppercase"
        fontWeight={600}
        fontSize={30}
        color="#1976d2"
      >
        Categorie:
      </Typography>
      <Stack component="div" direction="row" gap={10} my={10}>
        {Object.values(Pages).map(
          (page, index) =>
            isPageWithFile(page) && (
              <TopicButton
                key={index}
                onClick={() => setCurrentPage(page)}
                page={page}
              />
            )
        )}
      </Stack>
    </Box>
  );
}

type TopicButtonProps = {
  onClick(): void;
  page: PagesWithFile;
};

function TopicButton({ onClick, page }: TopicButtonProps): JSX.Element {
  return (
    <Button variant="contained" size="large" onClick={onClick}>
      {page}
    </Button>
  );
}

type TopicProps = {
  currentPage: Pages;
};

function Topic({ currentPage }: TopicProps): JSX.Element | null {
  const [score, setScore] = useState(0);
  const [questions, setQuestions] = useState(0);
  const [hasConfirmed, setHasConfirmed] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [data, setData] = useState<FileData | undefined>(
    useSelector(selectPageData(currentPage))
  );

  useEffect(() => {
    if (!data) return;
    setData(shuffleFileData(data));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [input, setInput] = useState("");

  if (!data) return <>"Data unavailable"</>;

  if (questions !== 0 && questions >= data.dialect.length) {
    return (
      <Typography>
        Ottimo lavoro, hai finito le parole di questa categoria. Il tuo
        punteggio è {score}/{questions}
      </Typography>
    );
  }

  return (
    <Stack alignItems="center" mt={5}>
      <Stack direction="row" alignItems="center">
        <Box component="div" pr={32} />
        <Typography
          textTransform="uppercase"
          fontWeight={600}
          fontSize={30}
          color="#1976d2"
          pr={30}
        >
          {currentPage}
        </Typography>
        <Typography>
          {score}/{questions}
        </Typography>
      </Stack>
      <Typography mt={15}>
        Traduci la seguente parola dal dialetto all'italiano:
      </Typography>
      <Stack mt={7} direction="row" width="fit-content">
        <Typography fontWeight={600}>{data.dialect[questions]}</Typography>
        {hasConfirmed && hasError && (
          <Stack direction="row">
            <Typography fontWeight={600}> = </Typography>
            <Typography fontWeight={600} color="green" whiteSpace="nowrap">
              {data.italian[questions]}
            </Typography>
          </Stack>
        )}
      </Stack>
      <TextField
        id="outlined-basic"
        label="Italiano"
        variant="outlined"
        value={input}
        error={hasError}
        color={hasConfirmed && !hasError ? "success" : "primary"}
        focused={(hasConfirmed && !hasError) || input.length > 0}
        onChange={(newVal) => setInput(newVal.target.value)}
      />
      {!hasConfirmed && (
        <Button
          sx={{ marginTop: 5 }}
          variant="contained"
          startIcon={<CheckIcon />}
          onClick={() => {
            if (!data) return;

            const received = input.toLowerCase().trim();
            const expected = data.italian[questions].toLowerCase().trim();

            if (received !== expected) {
              setHasError(true);
            }
            setHasConfirmed(true);
          }}
        >
          Conferma
        </Button>
      )}
      {hasConfirmed && (
        <Button
          sx={{ marginTop: 5 }}
          variant="contained"
          endIcon={<ArrowForwardIcon />}
          onClick={() => {
            if (hasConfirmed && !hasError) {
              setScore(score + 1);
            }
            setQuestions(questions + 1);

            setHasError(false);
            setHasConfirmed(false);
            setInput("");
          }}
        >
          Prossima parola
        </Button>
      )}
    </Stack>
  );
}
